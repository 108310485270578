.navButton {
  @apply flex h-full w-full items-center justify-between whitespace-nowrap py-3 font-secondary text-lg font-normal uppercase;
  @apply lg:border-b-4 lg:border-gray-800 lg:py-8 lg:text-base lg:font-light lg:text-gray-400 xl:text-lg;

  &:hover,
  &:focus {
    @apply text-red-base lg:border-yellow lg:text-yellow lg:outline-none;

    & .arrow {
      @apply stroke-red-base lg:rotate-180 lg:stroke-yellow;
    }
  }

  /* Focus state without expanding submenu */
  &:focus[aria-expanded='false'] {
    & .arrow {
      @apply lg:rotate-0;
    }
  }

  /* Submenu open */
  &[aria-expanded='true'] {
    @apply text-red-base lg:border-yellow lg:text-yellow lg:outline-none;

    & .arrow {
      @apply rotate-180 stroke-red-base lg:stroke-yellow;
    }
  }

  /* Logged in profile */
  &.profile {
    @apply after:absolute after:left-0.5 after:hidden after:h-[24px] after:w-[24px] after:bg-profile-icon after:bg-cover after:bg-no-repeat lg:relative;
    @apply after:lg:block;
  }

  & .profileIcon {
    @apply lg:mr-2;
  }

  & .arrow {
    @apply self-center stroke-black stroke-2 lg:pointer-events-none lg:ml-3 lg:stroke-gray-400;
  }
}
