.footer {
  @apply bg-black text-white;

  & .footerContainer {
    @apply relative flex flex-col items-center justify-center gap-y-4 py-10;
  }

  & .logoTagLine {
    @apply flex flex-col gap-1.5;

    & .logo {
      & > svg {
        @apply mb-0 h-auto w-56;
      }
    }

    & .tagLine {
      @apply font-primary text-lg font-light uppercase text-white;

      & span {
        @apply font-medium;
      }
    }
  }

  & p {
    @apply m-0 text-center text-sm text-gray-400;
  }

  @media print {
    @apply hidden;
  }
}
