.header {
  @apply sticky top-0 flex items-center justify-center bg-gray-800 py-[15px] sm:py-[17.5px];

  & .logo {
    & > svg {
      @apply h-auto w-[137px] sm:w-[155px];
    }
  }

  @media print {
    @apply hidden;
  }
}
