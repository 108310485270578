.containerW {
  @apply container lg:px-8;

  &:global(.extraWide) {
    @apply lg:max-w-[1800px];
  }

  &:global(.reduced),
  &:global(.reduced .hero__content) {
    @apply lg:max-w-[1216px];
  }

  &:global(.articleContainer) {
    @apply w-full max-w-[1152px] px-[1.375rem] xl:px-0;
  }

  &:global(.lotPage) {
    @apply max-w-[1800px];

    @media screen and (width <= 1799px) and (width >= 1600px) {
      @apply max-w-[1560px];
    }

    @media screen and (width <= 1599px) and (width >= 1400px) {
      @apply max-w-[1360px];
    }

    @media screen and (width <= 1399px) and (width >= 1200px) {
      @apply max-w-[1160px];
    }

    @media screen and (width <= 1199px) and (width >= 1024px) {
      @apply max-w-[960px];
    }
  }

  &.paddingTop {
    @apply pt-12;
  }

  &.paddingBtm {
    @apply pb-12;
  }
}
