.header {
  @apply sticky top-0 z-50 flex h-[84px] w-full items-center bg-gray-800 py-6 text-white xs:h-24 lg:h-auto lg:py-0;

  & li {
    @apply font-primary text-xl font-bold;
  }

  & .navigation {
    @apply flex items-center justify-between lg:relative;
  }

  & .mainNav {
    @apply absolute hidden gap-2 overflow-auto focus:outline-none lg:ml-[3%] lg:mr-auto lg:block lg:[position:unset] xl:ml-[5%];

    &.open {
      @screen lg_max {
        @apply container left-1/2 right-1/2 top-[84px] flex flex-col pb-20 xs:top-24;
        @apply -ml-[50vw] -mr-[50vw] h-[calc(100vh-84px)] w-[100vw] bg-white text-black xs:h-[calc(100vh-96px)];
      }
    }
  }

  & .closeMobileMenu {
    @apply mt-1.5 flex w-full justify-end lg:hidden;

    & button {
      @apply flex h-9 w-auto items-center justify-center font-secondary text-sm uppercase;

      & svg {
        @apply mr-1 h-6 w-6;
      }

      &:hover,
      &:focus {
        @apply text-red-base no-underline;
      }
    }
  }

  & .additionalNav {
    @apply flex items-center justify-end gap-2.5 lg:justify-center xl:gap-5;
  }

  & .primaryMenu {
    & > ul {
      @apply flex-col lg:flex-row;

      & > li > a {
        @apply px-0 lg:px-5;
      }
    }
  }

  & .searchButton {
    @apply ml-2 flex h-9 w-9 items-center justify-center lg:mr-4 xl:mr-5 2xl:mr-12;

    & svg {
      @apply cursor-pointer fill-gray-400 stroke-gray-400 stroke-2;
    }

    &:hover,
    &:focus {
      & svg {
        @apply fill-white stroke-white;
      }
    }
  }

  & .signIn,
  & .mobileNavBtn {
    @apply flex cursor-pointer items-center gap-2 text-lg uppercase;
  }

  & .mobileNavBtn {
    @apply font-primary text-xl tracking-[1px] text-gray-400 lg:hidden;

    &:hover,
    &:focus {
      & svg {
        @apply text-white;
      }
    }
  }

  & .logo {
    @apply flex justify-center lg:inline-block;

    & > svg {
      @apply h-auto w-24 md:w-44 lg:w-28 xl:w-36 2xl:w-44;
    }
  }

  & .mobileNavBtn,
  & .searchButton {
    &:focus-visible {
      @apply outline outline-offset-4 outline-yellow;
    }
  }

  /* Sign In controls */
  & .signIn {
    &.signInMobile {
      @apply border-b border-gray-400 pb-3 lg:hidden;

      & button {
        @apply flex py-3;

        & .profileIcon {
          @apply mr-4;
        }

        & svg + span {
          @apply ml-2;
        }

        & span {
          @apply mr-auto;
        }

        &:hover,
        &:focus {
          @apply text-red-base no-underline;
        }
      }
    }

    &.signInDesktop {
      @apply hidden lg:flex;

      & button {
        @apply whitespace-nowrap;
      }

      & .profile {
        @apply items-center lg:border-b-4 lg:border-b-gray-800 lg:py-7 xl:py-9;

        & svg {
          @apply fill-gray-400 lg:mx-auto lg:mb-1 xl:mb-0 xl:mr-4;
        }

        &:hover,
        &:focus {
          @apply lg:border-b-4 lg:border-b-yellow lg:text-yellow;

          & svg {
            @apply lg:fill-yellow;
          }
        }
      }
    }

    & button,
    & a {
      @apply uppercase;
    }

    & a {
      @apply whitespace-nowrap;
    }
  }

  @media print {
    @apply hidden;
  }
}

.skip {
  @apply absolute -left-[999px] top-auto -z-[999] overflow-hidden;
  @apply h-[1px] w-[1px];

  &:focus {
    @apply left-auto z-[999] h-auto w-fit overflow-auto bg-white p-2 text-sm no-underline;
  }
}
