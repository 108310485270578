.footer {
  @apply bg-black text-white;

  & .footerTop {
    @apply relative;
  }

  & .footerLegal {
    @apply bg-black text-sm md:text-base;

    & .footerContainer {
      @apply gap-y-4 py-6 md:gap-y-8 md:py-10;
    }

    & p {
      @apply m-0;
    }
  }

  & .footerContainer {
    @apply relative flex flex-col justify-between gap-y-8 py-6 md:flex-row md:items-end md:gap-x-8 md:gap-y-0 md:py-20;

    & ~ .footerContainer {
      @apply md:py-10;
    }
  }

  & .footerBorder {
    @apply relative h-2.5;
  }

  & .logo {
    & > svg {
      @apply mb-0 h-auto w-60;
    }
  }

  & .footerAds {
    @apply flex w-full flex-col justify-between lg:flex-row xl:justify-center;

    & > div {
      @apply mb-4 lg:mb-0 xl:mx-8;
    }
  }

  /* Footer Menu */
  & .footerMenu {
    @apply flex w-full flex-col justify-between gap-y-6 md:flex-row md:gap-x-10 md:gap-y-0;

    & > nav {
      @apply w-full text-left md:w-[unset];
    }

    & > nav > p {
      @apply md:min-h-[68px] md:items-start xl:min-h-0;
    }

    & button.footerMenuLabel {
      @apply flex w-full items-center justify-between text-left font-primary text-2xl font-medium uppercase tracking-[1.92px] text-yellow;
      @apply md:pointer-events-none;

      & > svg {
        @apply stroke-yellow stroke-2 md:hidden;
      }

      & [aria-expanded='true'] {
        & > svg {
          @apply rotate-180;
        }
      }
    }

    & ul {
      @apply m-0 mt-4 hidden gap-3 md:grid;

      &[aria-hidden='false'] {
        @apply grid;
      }
    }
  }

  & .footerMenu li,
  & .subFooterMenu li {
    @apply font-body text-lg font-normal uppercase leading-6 md:text-base md:font-light;

    & a {
      @apply block md:text-gray-400;

      &:hover,
      &:focus {
        @apply text-red-base underline md:border-yellow md:text-yellow;
      }
    }
  }

  /* Sub footer */
  & .subFooterMenu {
    & ul {
      @apply m-0 md:flex md:flex-wrap md:justify-between md:gap-x-6;

      & li {
        @apply font-primary text-xl font-medium;
      }
    }
  }

  @media print {
    @apply hidden;
  }
}
