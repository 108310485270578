.navigation {
  @apply lg_max:w-full;

  & ul.menu {
    @apply m-0 lg:flex lg:gap-x-3 xl:gap-x-8;

    /* Navigation items */
    & > li.menuListItem {
      /* Logged in profile */
      &.profile {
        & .subMenu {
          @apply lg:right-0 lg:w-auto;
        }
      }

      &:last-of-type:not(.profile) {
        @apply lg_max:mb-10;
      }
    }

    /* Drop Menus */
    & .subMenu {
      @apply absolute m-0 hidden px-0 lg:invisible lg:block;
      @apply lg:top-full lg:z-50 lg:w-[320px] lg:border lg:bg-white lg:px-6 lg:py-8;
      @apply transition lg:opacity-0 lg:shadow-[0_2px_20px_0_rgba(164,_162,_162,_0.5)] lg:transition-all lg:duration-150 lg:ease-linear;

      * {
        @apply lg:transform-none lg:shadow-none lg:transition-none;
      }

      &[aria-hidden='false'] {
        @apply visible block opacity-100 lg_max:static;
      }

      /* Nested UL */
      & > .subMenuList {
        @apply mb-0;

        & > .menuListItem {
          @apply text-lg;

          & .link {
            @apply block w-full rounded-[3px] py-2 pl-2 text-left font-secondary text-base font-bold uppercase text-gray-600 lg:px-5 lg:py-3;

            &:hover,
            &:focus-within {
              @apply text-red-base no-underline lg:border-none lg:bg-gray-100;
            }
          }

          &.hasChildren {
            & > .link {
              & > .arrow {
                @apply -mr-1 ml-auto rotate-0;
              }
            }
          }
        }
      }
    }

    /* Wide submenu items (mega menu) */
    .menuListItemContentWide > .subMenu {
      @apply left-0 lg:container lg:flex lg:px-5 2xl:left-1/2 2xl:-translate-x-1/2;

      & > .subMenuList {
        @apply lg:w-[320px] lg:border-r lg:border-gray-400 lg:pr-10;
      }

      & > .wideSubmenuItem {
        @apply hidden w-full pl-5 text-black lg:block;
      }
    }
  }

  & :global(.media-text) {
    @apply gap-3;
  }
}
